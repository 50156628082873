<template>
  <div class="flex items-center justify-end mb-3.5">
    <app-button
      feature="edit"
      @click="router.push({
        name: 'edit-profile',
      })"
    />
  </div>

  <!-- General information -->
  <app-card-item
    class="p-4 mb-6"
  >
    <div class="grid lg:grid-cols-3 grid-cols-1 gap-2">
      <div class="flex items-center">
        <!-- Profile picture -->
        <app-rounded-picture
          :picture-url="avatarUrl"
          picture-size="20"
          with-shadow
          class="mr-6"
        />

        <!-- Display name, roles & public id -->
        <div>
          <p class="text-3xl font-bold">
            {{ userDisplayName }}
          </p>

          <p class="font-bold">
            {{ authUserRoles }}
          </p>
        </div>
      </div>

      <div>
        <div class="grid grid-cols-1 w-fit gap-3">
          <div
            v-if="resource?.attributes?.name"
            class="grid grid-cols-2 gap-6"
          >
            <div class="font-medium">
              {{ capitalize(t('validation.attributes.nickname')) }}
            </div>

            <div>
              {{ resource.attributes.name }}
            </div>
          </div>

          <div
            v-if="resource?.attributes?.last_name"
            class="grid grid-cols-2 gap-6"
          >
            <div class="font-medium">
              {{ capitalize(t('validation.attributes.last_name')) }}
            </div>

            <div>
              {{ resource.attributes.last_name }}
            </div>
          </div>

          <div
            v-if="resource?.attributes?.first_name"
            class="grid grid-cols-2 gap-6"
          >
            <div class="font-medium">
              {{ capitalize(t('validation.attributes.first_name')) }}
            </div>

            <div>
              {{ resource.attributes.first_name }}
            </div>
          </div>

          <div
            v-if="!isEmptyContactPhone"
            class="grid grid-cols-2 gap-6"
          >
            <div class="font-medium">
              {{ capitalize(t('validation.attributes.contact_phone')) }}
            </div>

            <div>
              <app-data-grid-link
                type="phone"
                :link="resource.attributes.contact.phone"
              />
            </div>
          </div>

          <div
            v-if="!isEmptyContactEmail"
            class="grid grid-cols-2 gap-6"
          >
            <div class="font-medium">
              {{ capitalize(t('validation.attributes.contact_email')) }}
            </div>

            <div>
              <app-data-grid-link
                type="email"
                :link="resource.attributes.contact.email"
              />
            </div>
          </div>

          <div
            v-if="resource?.attributes?.contact?.website"
            class="grid grid-cols-2 gap-6"
          >
            <div class="font-medium">
              {{ capitalize(t('validation.attributes.website')) }}
            </div>

            <div>
              {{ resource.attributes.contact.website }}
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="grid grid-cols-1 w-fit gap-3">
          <div
            class="grid grid-cols-2 gap-6"
          >
            <div class="font-medium">
              {{ capitalize(t('validation.attributes.address')) }}
            </div>

            <div>
              <app-data-grid-address
                :address="resource?.relationships?.address"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-card-item>

  <div class="grid lg:grid-cols-2 grid-cols-1 gap-4 mb-4">
    <div>
      <app-section-title
        :title="t('ttmt.users.profile.security')"
        class="mb-4"
      />

      <div>
        <app-card-item class="p-4 col-start-1">
          <!-- Email -->
          <user-private-profile-param
            :name="t('validation.attributes.email')"
            :value="resource.attributes.email"
            route-name="edit-credential"
          />

          <!-- Password -->
          <user-private-profile-param
            :name="t('validation.attributes.password')"
            route-name="edit-profile"
            route-query="password"
          />

          <!-- Account deletion -->
          <user-private-profile-param
            :name="t('ttmt.extranet.profile.sections.delete_account')"
            route-name="delete-profile"
            feature="delete"
            action="delete"
            margin-bottom="mb-0"
          />
        </app-card-item>
      </div>

      <app-section-title
        :title="t('ttmt.users.profile.preferences')"
        class="my-6 mb-4"
      />

      <div>
        <app-card-item class="p-4 col-start-1">
          <!-- Currency -->
          <user-private-profile-param
            :name="t('ttmt.extranet.profile.sections.currency')"
            :value="resource.relationships.currency.attributes.name"
            route-name="edit-profile"
            route-query="currency"
            action="edit"
          />

          <!-- Locale -->
          <user-private-profile-param
            :name="t('ttmt.extranet.profile.sections.locale')"
            :value="capitalize(t(`ttmt.locales.${resource.attributes.locale}`))"
            route-name="edit-profile"
            route-query="locale"
            action="edit"
          />

          <!-- Notification settings -->
          <user-private-profile-param
            :name="t('ttmt.extranet.profile.sections.notification_settings')"
            route-name="edit-notification-settings"
            action="edit"
          />

          <!-- Privacy settings -->
          <user-private-profile-param
            :name="t('ttmt.extranet.profile.sections.privacy_settings')"
            route-name="edit-privacy-settings"
            action="edit"
            margin-bottom="mb-0"
          />
        </app-card-item>
      </div>
    </div>

    <user-payment
      :title="t('ttmt.extranet.profile.sections.payment')"
    >
      <!-- Bank account manage form-->
      <user-private-profile-param
        v-if="resource?.attributes?.can_update_bank_account"
        :name="t('validation.attributes.bank_account')"
        :value="hasBankAccount ? t('validation.attributes.specified') : t('validation.attributes.not_specified')"
        route-name="edit-profile"
        route-query="bank_account"
      />

      <!-- Tips enabled -->
      <user-private-profile-param
        :name="t('validation.attributes.tips_enabled')"
        :value="capitalize(t(`ttmt.common.${resource.attributes.tips_enabled}`))"
        route-name="edit-profile"
        route-query="tips_enabled"
      />
    </user-payment>

    <div>
      <!-- Institution -->
      <template v-if="applyInstitution">
        <app-section-title
          :title="t('ttmt.breadcrumbs.institutions.institutions')"
          class="mb-4 mt-6"
        />

        <app-card-item class="p-4 col-start-1">
          <user-private-profile-param
            :name="t('ttmt.users.profile.form.poi_label')"
            route-name="institutions"
            :value="isInstitution ? institutionTitle : null"
          />
        </app-card-item>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { capitalize, isNil } from 'lodash'

import useLocale from '@shared/hooks/locale'
import useUser from '@shared/hooks/resources/user'
import useResource from '@shared/hooks/resources/resource'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppDataGridAddress from '@extranet/components/ui/data_grid/AppDataGridAddress.vue'
import AppDataGridLink from '@extranet/components/ui/data_grid/AppDataGridLink.vue'
import AppRoundedPicture from '@shared/components/ui/AppRoundedPicture.vue'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import UserPayment from '@extranet/components/resources/user/UserPayment.vue'
import UserPrivateProfileParam from '@extranet/components/resources/user/UserPrivateProfileParam.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is it loading?
  loading: {
    type: Boolean,
    default: true,
  },
})

const store = useStore()
const router = useRouter()

const { t } = useI18n()
const { getImageUrlFromResource } = useResource()
const { getAttributeTranslation } = useLocale()

// ---------- BANK ACCOUNT ----------

const hasBankAccount = computed(() => (
  props.resource.attributes?.has_bank_account
    || props.resource.relationships?.point_of_interest?.relationships?.owner?.attributes?.has_bank_account
))

// ---------- USER ----------

const {
  roles: authUserRoles,
  displayName: userDisplayName,
} = useUser({ resource: store.state.auth.user })

const avatarUrl = computed(() => (
  getImageUrlFromResource(props.resource)
))

// ---------- AVAILABLE INFOS ----------

const isEmptyContactPhone = computed(() => (
  isNil(props.resource?.attributes?.contact?.phone)
))

const isEmptyContactEmail = computed(() => (
  isNil(props.resource?.attributes?.contact?.email)
))

// ---------- INSTITUTION ----------

const applyInstitution = computed(() => (
  store.state.auth.user.authorizations.points_of_interest.apply
))

const isInstitution = computed(() => (
  props.resource.relationships.point_of_interest
))

const institutionTitle = computed(() => (
  getAttributeTranslation(props.resource.relationships.point_of_interest?.attributes?.title)
))
</script>
