<template>
  <div
    v-if="success"
    class="max-w-screen-md"
  >
    <app-section-title
      :title="t('ttmt.user_credential_verifications.verify.sent.title')"
      class="mb-3.5"
    />

    <p class="mb-2">
      {{ t('ttmt.user_credential_verifications.verify.sent.email_1', { email: values.credential }) }}
    </p>

    <p class="mb-3">
      {{ t('ttmt.user_credential_verifications.verify.sent.email_2') }}
    </p>

    <p class="mb-3">
      {{ t('ttmt.user_credential_verifications.verify.sent.email_3') }}
    </p>

    <div>
      <app-button
        emphasis="low"
        :label="t('ttmt.user_credential_verifications.verify.sent.again')"
        whitespace-classes="whitespace-normal"
        :loading="submitting"
        :disabled="submitting || !sendCooldownEnded"
        display-classes="flex"
        class="mx-auto"
        width="fit"
        @click="handleSubmit"
      />

      <p
        v-if="!sendCooldownEnded"
        class="text-xs text-center"
      >
        {{ t('ttmt.user_credential_verifications.verify.sent.wait_before', { seconds: sendCooldown }) }}
      </p>
    </div>
  </div>

  <!-- Use v-show instead of v-if, to not lose form values -->
  <form
    v-show="!success"
    novalidate
    @submit.prevent="handleSubmit"
  >
    <app-card-item
      class="p-4 max-w-screen-md clear-both"
    >
      <form-global-errors />

      <fieldset
        :disabled="submitting"
        class="clear-both"
      >
        <p class="text-sm mb-1">
          {{ t('ttmt.auth.credential_verification.intro') }}
        </p>
        <p class="text-sm mb-3">
          {{ t('ttmt.auth.credential_verification.intro2') }}
        </p>

        <form-group
          name="credential"
          rules="email|required"
          type="email"
          :label="t('validation.attributes.email')"
          :initial-value="resource.attributes.email"
          margins="mb-1"
        />
      </fieldset>
    </app-card-item>

    <app-button
      feature="save"
      type="submit"
      :disabled="submitting || invalid"
      :loading="submitting"
      class="float-right mt-4"
    />
  </form>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import AppButton from '@shared/components/ui/AppButton.vue'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import useForm from '@shared/hooks/form/form'

const props = defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not generated
  // by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
  // Link succeed to send or not
  success: {
    type: Boolean,
    default: false,
  },
  // Cooldown before allow sending again
  sendCooldown: {
    type: Number,
    default: 0,
  },
})

const emits = defineEmits([
  'submitted',
])

const { t } = useI18n()

const {
  handleSubmit,
  invalid,
  values,
} = useForm(props, { emits })

// ---------- SEND COOLDOWN ----------

const sendCooldownEnded = computed(() => (
  props.sendCooldown <= 0
))
</script>
