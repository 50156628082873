<template>
  <teleport-wrapper
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <app-alert
    v-show="!hasBankAccount"
    design="warning"
    :title="t('ttmt.bank.account.alerts.no_account_yet')"
    class="mb-6"
  >
    <!-- eslint-disable vue/no-v-html -->
    <span v-html="t('ttmt.bank.account.lines.no_account_yet')" />
  </app-alert>

  <user-private-profile-resource
    v-if="user && !store.state.spinner.active"
    :resource="user"
    :loading="false"
  />
</template>

<script setup>
import {
  onMounted,
  ref,
  computed,
} from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import { fetchCurrentUser } from '@shared/http/api'
import breadcrumbUser from '@extranet/breadcrumbs/user'
import AppAlert from '@shared/components/ui/AppAlert.vue'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import UserPrivateProfileResource from '@extranet/components/resources/user/UserPrivateProfileResource.vue'

const store = useStore()
const { t } = useI18n()

// ---------- USER ----------

const user = ref(null)
const params = {
  'mode': 'private-profile',
}

function fetchUser() {
  store.commit('spinner/ENABLE')

  fetchCurrentUser(params)
    .then((response) => {
      user.value = response.data.data
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
    })
}

// ---------- BANK ACCOUNT ----------

const hasBankAccount = computed(() => (
  user.value?.attributes?.has_bank_account
    || user.value?.relationships?.point_of_interest?.relationships?.owner?.attributes?.has_bank_account
))

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchUser()
})

// ---------- BREADCRUMBS ----------

const { profile } = breadcrumbUser()

const breadcrumbs = computed(() => (
  profile()
))
</script>
