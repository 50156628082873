<template>
  <!-- Page title -->
  <teleport-wrapper to="#mainTitle">
    {{ t('ttmt.breadcrumbs.users.users') }}
  </teleport-wrapper>

  <!-- Breadcrumbs -->
  <teleport-wrapper
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <!-- Search -->
  <div class="w-full mt-2">
    <form-control-text
      name="users_search"
      :placeholder="t('ttmt.users.index.search')"
      :icon-props="{ library: 'coolicon', name: 'search', classes: ['form-icon-theme'], size: 'text-2xl' }"
      class="max-w-sm w-full flex-1 mb-3"
      @changed="handleSearch"
    />

    <form-hint
      :hint="t('ttmt.users.index.hint')"
    />
  </div>

  <div class="max-w-md">
    <template v-if="!loading">
      <!-- Results -->
      <template
        v-if="users.length > 0"
      >
        <app-card-item
          v-for="(user, i) in users"
          :key="i"
          class="overflow-hidden my-4 p-4"
        >
          <user-card
            v-bind="user"
          />
        </app-card-item>
      </template>

      <!-- No results -->
      <p
        v-else-if="searchDone"
        class="mt-2 text-center"
      >
        {{ t('ttmt.users.index.no_results') }}
      </p>

      <!-- Load more results -->
      <template v-if="lastPage && page < lastPage">
        <a
          v-if="nextPageFetching === false"
          href="#"
          class="block text-center app-link w-full"
          @click.prevent="nextPage"
        >
          {{ t('ttmt.common.actions.load_more') }}
        </a>
      </template>

      <div
        v-if="nextPageFetching"
        class="text-center w-full"
      >
        <app-spinner
          class="text-theme-500"
          size="lg"
        />
      </div>
    </template>

    <!-- Results loading -->
    <template v-else>
      <user-card-empty
        v-for="i in 7"
        :key="i"
        class="my-4"
      />
    </template>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import {
  fetchUsers as fetchApiUsers,
} from '@shared/http/api'
import breadcrumbUser from '@extranet/breadcrumbs/user'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppSpinner from '@shared/components/ui/AppSpinner.vue'
import FormControlText from '@shared/components/form/controls/FormControlText.vue'
import FormHint from '@shared/components/form/FormHint.vue'
import UserCard from '@extranet/components/resources/user/UserCard.vue'
import UserCardEmpty from '@extranet/components/resources/user/UserCardEmpty.vue'

const { t } = useI18n()

const users = ref([])
const nextPageFetching = ref(false)
const page = ref(1)
const lastPage = ref(null)
const loading = ref(false)
const searchDone = ref(false)

function fetchUsers(nextPage = false) {
  return new Promise((resolve) => {
    if (nextPage === false) {
      loading.value = true
    }

    nextPageFetching.value = nextPage

    const params = {
      'page': page.value,
      'search': searchKeywords.value,
    }

    fetchApiUsers(params)
      .then((response) => {
        const oldUsers = users.value
        const newUsers = response.data.data?.map((user) => ({
          resource: user,
        })) ?? []

        if (nextPage) {
          // Add new results after the old ones
          users.value = [...oldUsers, ...newUsers]
        } else {
          // Replace the old results with the new ones
          users.value = newUsers
        }

        lastPage.value = response.data.meta?.last_page
      })
      .catch(() => {})
      .finally(() => {
        nextPageFetching.value = false
        loading.value = false
        resolve()
      })
  })
}

function resetPage() {
  page.value = 1
}

function nextPage() {
  page.value += 1
  fetchUsers(true)
}

const typingTimer = ref(0)
const searchKeywords = ref(null)

function handleSearch(value) {
  // Wait a little before emit the 'searched event'
  // it allows user to finish typing, and avoid to trigger an api call at each keystroke
  if (typingTimer.value) {
    clearTimeout(typingTimer.value)
  }

  if (value) {
    typingTimer.value = setTimeout(() => {
      resetPage()
      searchKeywords.value = value
      searchDone.value = true
      fetchUsers()
    }, 400)
  } else {
    // Empty results if there is no search keyword
    users.value = []
    searchDone.value = false
  }
}

// ---------- BREADCRUMBS ----------

const { index } = breadcrumbUser()

const breadcrumbs = computed(() => (
  index()
))

</script>
