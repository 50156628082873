<template>
  <!-- Actions -->
  <user-actions
    v-if="withActions"
    :resource="resource"
    class="mb-4"
    @contact-updated="handleContactUpdated"
  />

  <div class="grid lg:grid-cols-2 grid-cols-1 gap-4">
    <div>
      <app-section-title
        :title="t('ttmt.extranet.profile.sections.infos')"
        class="mb-3.5"
      />

      <app-card-item
        :class="`p-4 relative ${canGiveTip ? 'pb-10 mb-4' : ''}`"
      >
        <div class="flex items-center mb-5">
          <!-- Profile picture -->
          <app-rounded-picture
            :picture-url="avatarUrl"
            picture-size="20"
            with-shadow
            class="mr-6"
          />

          <!-- Display name, roles & public id -->
          <div>
            <p class="text-3xl font-bold">
              {{ userDisplayName }}
            </p>

            <p class="font-bold">
              {{ authUserRoles }}
            </p>
          </div>
        </div>

        <div>
          <div class="grid grid-cols-1 w-fit gap-3">
            <div
              v-if="resource?.attributes?.name"
              class="grid grid-cols-2 gap-6"
            >
              <div class="font-medium">
                {{ capitalize(t('validation.attributes.nickname')) }}
              </div>

              <div>
                {{ resource.attributes.name }}
              </div>
            </div>

            <div
              v-if="resource?.attributes?.last_name"
              class="grid grid-cols-2 gap-6"
            >
              <div class="font-medium">
                {{ capitalize(t('validation.attributes.last_name')) }}
              </div>

              <div>
                {{ resource.attributes.last_name }}
              </div>
            </div>

            <div
              v-if="resource?.attributes?.first_name"
              class="grid grid-cols-2 gap-6"
            >
              <div class="font-medium">
                {{ capitalize(t('validation.attributes.first_name')) }}
              </div>

              <div>
                {{ resource.attributes.first_name }}
              </div>
            </div>

            <div
              v-if="resource?.attributes?.country"
              class="grid grid-cols-2 gap-6"
            >
              <div class="font-medium">
                {{ capitalize(t('validation.attributes.country')) }}
              </div>

              <div>
                {{ resource.attributes.country }}
              </div>
            </div>

            <div
              v-if="!isEmptyContactPhone"
              class="grid grid-cols-2 gap-6"
            >
              <div class="font-medium">
                {{ capitalize(t('validation.attributes.contact_phone')) }}
              </div>

              <div>
                <app-data-grid-link
                  type="phone"
                  :link="resource.attributes.contact_phone"
                />
              </div>
            </div>

            <div
              v-if="!isEmptyContactEmail"
              class="grid grid-cols-2 gap-6"
            >
              <div class="font-medium">
                {{ capitalize(t('validation.attributes.contact_email')) }}
              </div>

              <div>
                <app-data-grid-link
                  type="email"
                  :link="resource.attributes.contact_email"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Tip -->
        <app-tip-button
          :resource="resource"
          :can-give-tip="canGiveTip"
          tip-form-mode="extranet"
        />
      </app-card-item>
    </div>

    <div>
      <!-- Interactions -->
      <app-section-title
        :title="t('ttmt.traveller_folders.pages.show.sections.interactions')"
        class="mb-3.5"
      />

      <interaction-table
        v-if="resource"
        mode="traveller"
        :filter-id="resource.id"
      />

      <!-- Payments -->
      <payment-profile
        v-if="resource"
        :butler-id="resource.id"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { capitalize, isNil } from 'lodash'

import useResource from '@shared/hooks/resources/resource'
import useUser from '@shared/hooks/resources/user'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppDataGridLink from '@extranet/components/ui/data_grid/AppDataGridLink.vue'
import AppRoundedPicture from '@shared/components/ui/AppRoundedPicture.vue'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import AppTipButton from '@shared/components/features/tips/AppTipButton.vue'
import InteractionTable from '@extranet/components/resources/interaction/InteractionTable.vue'
import PaymentProfile from '@extranet/components/resources/payment/PaymentProfile.vue'
import UserActions from '@extranet/components/resources/user/UserActions.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Display actions or not
  withActions: {
    type: Boolean,
    default: true,
  },
})

const emits = defineEmits([
  'contactUpdated',
])

const { t } = useI18n()
const { getImageUrlFromResource } = useResource()

const relation = ref(props.resource.relationships.users_relationship)

// ---------- TIP ----------

// Can give tip if user can receive payments
// and relation is not blocked
const canGiveTip = computed(() => (
  props.resource?.attributes?.can_receive_payments
    && relation.value?.attributes?.state !== 'blocked'
))

// ---------- USER ----------

const {
  roles: authUserRoles,
  displayName: userDisplayName,
} = useUser({ resource: props.resource })

const avatarUrl = computed(() => (
  getImageUrlFromResource(props.resource, 'sm')
))

function handleContactUpdated() {
  emits('contactUpdated')
}

// ---------- AVAILABLE INFOS ----------

const isEmptyContactPhone = computed(() => (
  isNil(props.resource?.attributes?.contact_phone)
))

const isEmptyContactEmail = computed(() => (
  isNil(props.resource?.attributes?.contact_email)
))
</script>
