<template>
  <div :class="marginBottom">
    <div class="flex items-center justify-between">
      <p class="mr-6 text-lg font-bold">
        {{ capitalize(name) }}
      </p>

      <app-button
        :label="t(`ttmt.common.actions.${action}`)"
        :feature="feature"
        emphasis="low"
        font-size="lg"
        @click="router.push(routeParams)"
      />
    </div>

    <p>
      {{ value ?? '&nbsp;' }}
    </p>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { capitalize } from 'lodash'

import AppButton from '@shared/components/ui/AppButton.vue'

const router = useRouter()
const { t } = useI18n()

const props = defineProps({
  // Param name
  name: {
    type: String,
    required: true,
  },
  // Param value
  value: {
    type: String,
    default: null,
  },
  // Action name
  action: {
    type: String,
    default: 'manage',
  },
  // Action feature
  feature: {
    type: String,
    default: null,
  },
  // Name of the route
  routeName: {
    type: String,
    required: true,
  },
  // Query params
  // for the route
  routeQuery: {
    type: String,
    default: null,
  },
  // Bottom margin
  marginBottom: {
    type: String,
    default: 'mb-4',
  },
})

// ---------- ROUTE ----------

const routeParams = computed(() => {
  const params = {}

  params.name = props.routeName

  if (props.routeQuery) {
    params.query = {
      mode: props.routeQuery,
    }
  }

  return params
})
</script>
